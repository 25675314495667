var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    _vm._g(
      _vm._b(
        {
          class: {
            button: true,
            "no-padding": _vm.noPadding
          },
          attrs: { text: "" }
        },
        "v-btn",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }