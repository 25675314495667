<template>
  <layout :showTopBanner="false" :showSidebar="false">
    <app-header :leftLogoPersist="true" :showMobileMenuButton="false" />
    <v-card class="container" rounded="xl">
      <h1 class="top-title">Join your peers</h1>
      <h4 class="mt-2">
        Hi {{ user.first_name }}. Your email is connected to the
        <span class="inqliPurple--text">{{ org.name }}</span> organization.
      </h4>
      <v-img
        contain
        class="my-4 org-logo"
        :src="org.logo_url"
        alt="Organization logo"
        v-if="org.logo_url"
      />
      <div class="org-logo-placeholder" v-else>
        <v-avatar color="inqliPurple" size="100">
          <span class="white--text">{{ org.name }}</span>
        </v-avatar>
      </div>
      <form @submit.prevent="submit">
        <inqli-button
          width="100%"
          class="mt-2"
          type="submit"
          :isProcess="isSubmitting"
          >Join</inqli-button
        >
      </form>
      <text-button class="skip-button" @click="goToDashboard">Skip</text-button>
    </v-card>
    <v-dialog v-model="dialog" max-width="456px" persistent>
      <v-card class="py-8 px-5" rounded="xl">
        <div v-if="org.logo_url">
          <v-img
            contain
            class="mb-6 org-logo"
            :src="org.logo_url"
            alt="Organization logo"
          />
        </div>
        <div class="org-logo-placeholder" v-else>
          <v-avatar color="inqliPurple" size="100">
            <span class="white--text">{{ org.name }}</span>
          </v-avatar>
        </div>
        <div class="body-1" v-for="text in dialogTexts" :key="text">
          {{ text }}
        </div>

        <inqli-button width="120px" class="mt-4" @click="goToDashboard">{{
          dialogButtonText
        }}</inqli-button>
      </v-card>
    </v-dialog>
  </layout>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import sendRequest from "../app/utils/send-request-helper";
import Layout from "../app/components/layouts/DefaultLayout.vue";
import Header from "../app/components/headers/DefaultHeader";
import InqliButton from "../app/components/buttons/Button.vue";
import TextButton from "../app/components/buttons/TextButton.vue";

export default {
  metaInfo() {
    return {
      title: "Join " + this.org.name,
      meta: [
        {
          name: "description",
          content: this.org.description,
        },
      ],
    };
  },
  data() {
    return {
      org: {},
      roleInputError: false,
      dialog: false,
      dialogTexts: [],
      dialogButtonText: "",
      role: "",
      isSubmitting: false,
    };
  },
  validations: {
    role: {
      required,
    },
  },
  components: {
    layout: Layout,
    "app-header": Header,
    "inqli-button": InqliButton,
    "text-button": TextButton,
  },
  async mounted() {
    try {
      const hasOrg = await this.checkUserOrg();
      if (hasOrg) {
        throw new Error("User already belonged to an organization");
      }
      const org = await this.getOrgByUserDomain();
      if (!org) {
        throw new Error("Organization not found by user domain");
      }
      this.org = org;
    } catch (error) {
      this.$store.dispatch("callAlert", error.message);
      this.goToDashboard();
    }
  },
  computed: {
    user() {
      return this.$store.state.user.info ? this.$store.state.user.info : {};
    },
  },
  methods: {
    async getOrgByUserDomain() {
      const userEmail = this.$store.state.user.info.email;
      if (!userEmail) {
        throw new Error("User email is not available");
      }
      const userDomain = userEmail.split("@")[1];
      const org = await this.checkOrgByDomain(userDomain);
      return org;
    },
    async checkOrgByDomain(domain) {
      const data = await sendRequest({
        url: `/resources/organizations/domain/${domain}`,
        method: "GET",
        isAuth: true,
      });
      return data;
    },
    async checkUserOrg() {
      await this.$store.dispatch("loadUserOrganization");
      const orgs = this.$store.state.user.organizations;
      return Array.isArray(orgs) && orgs.length > 0;
    },
    async submit() {
      if (this.isSubmitting) return;
      this.isSubmitting = true;

      this.roleInputError = false;
      try {
        await this.joinOrg();
        this.openDialog({
          text: [`Congrats! You have successfully joined ${this.org.name}`],
          buttonText: "Done",
        });
      } catch (error) {
        this.openDialog({
          text: [
            `There was an error processing your request to join ${this.org.name}`,
            "Please try again later.",
          ],
          buttonText: "Dismiss",
        });
      }
      this.isSubmitting = false;
    },
    openDialog({ text, buttonText }) {
      this.dialog = true;
      this.dialogTexts = text;
      this.dialogButtonText = buttonText;
    },
    async joinOrg() {
      await sendRequest({
        url: `/relationships/member?source_id=${this.user.id}&dest_id=${this.org.id}`,
        method: "POST",
        isAuth: true,
      });
      this.$store.dispatch("loadUserOrganization");
    },
    goToDashboard() {
      this.$router.push("/users/" + this.user.id);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  max-width: 650px;
  width: 60%;
  flex: 1;
  margin: 0 auto;
  margin-top: 30px;
  padding: 30px 64px;
  padding-bottom: 40px;
  .top-title {
    color: $inqliGreen;
  }
  form {
    padding: 0 20px;
    margin: auto;
    width: 70%;
  }
}
.org-logo-placeholder {
  margin: auto;
}
.org-logo {
  width: 100%;
  max-width: 200px;
  margin: auto;
  border-radius: 16px;
}
.skip-button {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
@media screen and (max-width: $breakpoint-mobile) {
  .container {
    width: 95%;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    form {
      width: 100%;
      padding: 10px;
    }
  }
}
</style>
