<template>
  <v-btn
    :class="{
      button: true,
      'no-padding': noPadding,
    }"
    text
    v-on="$listeners"
    v-bind="$attrs"
  >
    <slot></slot>
  </v-btn>
</template>
<script>
/**
 * TextButton: only display text
 */
export default {
  name: "InqliTextButton",
  props: {
    isProcess: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.v-btn.button {
  font-size: 12px;
  font-weight: 600;
  border: none;
  outline: none;
  color: $inqliPurple;
}
.v-btn.button:hover {
  color: $inqliButtonHover !important;
  border: none;
  background-color: transparent !important;
}
.no-padding {
  padding: 0 !important;
}
.v-btn.button:focus {
  color: $inqliButtonFocus !important;
  border-color: white !important;
}
</style>
