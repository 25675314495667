var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { showTopBanner: false, showSidebar: false } },
    [
      _c("app-header", {
        attrs: { leftLogoPersist: true, showMobileMenuButton: false }
      }),
      _c(
        "v-card",
        { staticClass: "container", attrs: { rounded: "xl" } },
        [
          _c("h1", { staticClass: "top-title" }, [_vm._v("Join your peers")]),
          _c("h4", { staticClass: "mt-2" }, [
            _vm._v(
              " Hi " +
                _vm._s(_vm.user.first_name) +
                ". Your email is connected to the "
            ),
            _c("span", { staticClass: "inqliPurple--text" }, [
              _vm._v(_vm._s(_vm.org.name))
            ]),
            _vm._v(" organization. ")
          ]),
          _vm.org.logo_url
            ? _c("v-img", {
                staticClass: "my-4 org-logo",
                attrs: {
                  contain: "",
                  src: _vm.org.logo_url,
                  alt: "Organization logo"
                }
              })
            : _c(
                "div",
                { staticClass: "org-logo-placeholder" },
                [
                  _c(
                    "v-avatar",
                    { attrs: { color: "inqliPurple", size: "100" } },
                    [
                      _c("span", { staticClass: "white--text" }, [
                        _vm._v(_vm._s(_vm.org.name))
                      ])
                    ]
                  )
                ],
                1
              ),
          _c(
            "form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "inqli-button",
                {
                  staticClass: "mt-2",
                  attrs: {
                    width: "100%",
                    type: "submit",
                    isProcess: _vm.isSubmitting
                  }
                },
                [_vm._v("Join")]
              )
            ],
            1
          ),
          _c(
            "text-button",
            { staticClass: "skip-button", on: { click: _vm.goToDashboard } },
            [_vm._v("Skip")]
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "456px", persistent: "" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { staticClass: "py-8 px-5", attrs: { rounded: "xl" } },
            [
              _vm.org.logo_url
                ? _c(
                    "div",
                    [
                      _c("v-img", {
                        staticClass: "mb-6 org-logo",
                        attrs: {
                          contain: "",
                          src: _vm.org.logo_url,
                          alt: "Organization logo"
                        }
                      })
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "org-logo-placeholder" },
                    [
                      _c(
                        "v-avatar",
                        { attrs: { color: "inqliPurple", size: "100" } },
                        [
                          _c("span", { staticClass: "white--text" }, [
                            _vm._v(_vm._s(_vm.org.name))
                          ])
                        ]
                      )
                    ],
                    1
                  ),
              _vm._l(_vm.dialogTexts, function(text) {
                return _c("div", { key: text, staticClass: "body-1" }, [
                  _vm._v(" " + _vm._s(text) + " ")
                ])
              }),
              _c(
                "inqli-button",
                {
                  staticClass: "mt-4",
                  attrs: { width: "120px" },
                  on: { click: _vm.goToDashboard }
                },
                [_vm._v(_vm._s(_vm.dialogButtonText))]
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }